<script setup lang="ts">
import { VNavigationDrawer } from "vuetify/components/VNavigationDrawer";

// v-model
const modelValue = defineModel<boolean>({
  default: false,
});

const { sidebarLinks } = useSidebarLinks();
const { checkPermission } = useAuthorization();

const sidebarLinksFiltered = computed(() => {
  return sidebarLinks.filter((link) =>
    checkPermission({ adminRolesAllowed: link.adminRolesAllowed })
  );
});
</script>

<template>
  <VNavigationDrawer v-model="modelValue">
    <MList v-if="!!sidebarLinksFiltered">
      <template
        v-for="(link, index) in sidebarLinksFiltered"
        :key="index"
      >
        <template
          v-if="checkPermission({ adminRolesAllowed: link.adminRolesAllowed })"
        >
          <ListItemLink
            :key="`${link.title}-${index}`"
            :route="link.to"
            :prependIcon="link.icon"
            :title="link.title"
            :exact="!!link.exact"
          />
        </template>
      </template>
    </MList>
  </VNavigationDrawer>
</template>

<style>
.v-navigation-drawer__content::-webkit-scrollbar {
  display: none !important;
}

.v-navigation-drawer__content {
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}
</style>
